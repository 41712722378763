html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    width: 1px;
    height: 1px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--chakra-colors-charadeDark3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-corner {
    background-color: var(--chakra-colors-charadeDark3);
  }
}