.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  width: 200px !important;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  width: 80%;
}

.swiper-slide:nth-child(2n) {
  width: 60%;
}

.swiper-slide:nth-child(3n) {
  width: 40%;
}

/* Style for navigation buttons */
.custom-nav {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4caf50; /* Green background */
  color: white; /* White arrow */
  border-radius: 50%; /* Makes the button circular */
  position: absolute;
  top: 50%; /* Vertically center the button */
  transform: translateY(-50%); /* Adjust vertical centering */
  cursor: pointer;
  z-index: 10; /* Ensure the button is above the slides */
  font-size: 20px; /* Arrow size */
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

/* Position the buttons */
.swiper-button-prev {
  left: 10px; /* Position the previous button on the left */
}

.swiper-button-next {
  right: 10px; /* Position the next button on the right */
}

/* Hover effect for buttons */
.custom-nav:hover {
  background-color: #45a049; /* Darker green on hover */
  transform: translateY(-50%) scale(1.1); /* Slightly enlarge on hover */
}
